import React, { Fragment, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion";
import Image from "../../../components/image";
import ImageOld from "../../../components/imageOld";
import { Container, Row, Col } from "../../../components/ui/wrapper";
import SectionTitle from "../../../components/ui/section-title";
import AccordionWrap from "../../../components/ui/accordion";
//import VideoButton from "../../../components/ui/video-button";
import ModalVideo from "../../../components/ui/modal-video";
import {
  AboutAreaWrap,
  AboutTextBox,
  AboutImageBox,
  ImageBox1,
  ImageBox2,
  ImageBox3,
  ImageBox4,
  MainImageBox
} from "./about-area.style";
import Text from "../../../components/ui/text";
import Button from "../../../components/ui/button";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const AboutArea = props => {
  const AboutData = useStaticQuery(graphql`
    query ProcessingAboutQuery {
      infotechnoJson(id: { eq: "infotechno-about-content" }) {
        title
        subtitle
        desc
        path
        path_label
        faq {
          id
          content
          title
        }
        image1 {
          childImageSharp {
            gatsbyImageData(
              width: 310
              height: 190
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        image2 {
          childImageSharp {
            gatsbyImageData(
              width: 188
              height: 115
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        image3 {
          childImageSharp {
            gatsbyImageData(
              width: 188
              height: 115
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        image4 {
          childImageSharp {
            gatsbyImageData(
              width: 190
              height: 190
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        main_image {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  `);
  const {
    title,
    subtitle,
    faq,
    image1,
    image2,
    image3,
    image4,
    main_image,
    desc,
    path,
    path_label
  } = AboutData.infotechnoJson;

  const { sectionTitleStyle, descStyle, btnStyle } = props;
  
  const mainImage = getImage(main_image);
  const sideImage1 = getImage(image1);
  const sideImage2 = getImage(image2);
  const sideImage3 = getImage(image3);
  const sideImage4 = getImage(image4);

  return (
    <Fragment>
      <AboutAreaWrap>
        <Container fluid>
          <Row alignitems="center">
            <Col lg={6}>
              <AboutTextBox>
                <SectionTitle
                  {...sectionTitleStyle}
                  title={title}
                  subtitle={subtitle}
                />
                {desc && <Text {...descStyle} dangerouslySetInnerHTML={{ __html: desc }}/>}
                {(path && path_label) && (
                  <Button to={path} {...btnStyle}>
                    {path_label}
                  </Button>
                )}
                {faq && (
                  <AccordionWrap>
                    <Accordion allowZeroExpanded preExpanded={[0]}>
                      {faq.map((el, index) => {
                        return (
                          <AccordionItem id={el.id} key={index}>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                {el.title}
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>{el.content}</p>
                            </AccordionItemPanel>
                          </AccordionItem>
                        );
                      })}
                    </Accordion>
                  </AccordionWrap>
                )}
              </AboutTextBox>
            </Col>
            <Col lg={6}>
              <AboutImageBox>
                {image1 && (
                  <ImageBox1 className="animation_image one">
                    <GatsbyImage image={sideImage1}
                      style={{
                        position: 'absolute',
                      }}
                      alt="About Banner"
                    />
                  </ImageBox1>
                )}
                {image2 && (
                  <ImageBox2 className="animation_image two">
                    <GatsbyImage image={sideImage2}
                      style={{
                        position: 'absolute',
                      }}
                      alt="About Banner"
                    />
                  </ImageBox2>
                )}
                {main_image && (
                  <MainImageBox>
                     <GatsbyImage image={mainImage}
                      alt="About Banner"
                    />
                  </MainImageBox>
                )}
                {image3 && (
                  <ImageBox3 className="animation_image three">
                    <GatsbyImage image={sideImage3}
                      style={{
                        position: 'absolute',
                        marginTop: '100px'
                      }}
                      alt="About Banner"
                    />
                  </ImageBox3>
                )}
                {image4 && (
                  <ImageBox4 className="animation_image four">
                    <GatsbyImage image={sideImage4}
                      style={{
                        position: 'absolute',
                        marginTop: '100px'
                      }}
                      alt="About Banner"
                    />
                  </ImageBox4>
                )}
              </AboutImageBox>
            </Col>
          </Row>
        </Container>
      </AboutAreaWrap>
      {/* <ModalVideo
        channel={video_channel}
        videoId={video_id}
        isOpen={videoOpen}
        onClose={modalVideoClose}
      /> */}
    </Fragment>
  );
};

AboutArea.propTypes = {
  descStyle: PropTypes.object,
  btnStyle: PropTypes.object,
  sectionTitleStyle: PropTypes.object
};

AboutArea.defaultProps = {
  descStyle: {
    mb: "30px"
  },
  btnStyle: {
    hover: "2",
    mb: "30px"
  },
  sectionTitleStyle: {
    mb: "40px",
    align: "left"
  }
};

export default AboutArea;
