import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import Hero from '../containers/infotechno/hero'
import ClientsArea from '../containers/global/clients-area/section-one'
import FeaturesArea from '../containers/infotechno/features-area'
import AboutArea from '../containers/infotechno/about-area'
import ServicesArea from '../containers/infotechno/services-area'
import AboutServiceWrap from '../containers/infotechno/about-service-wrap'
import FunFactArea from '../containers/global/funfact-area/section-two'
import VideoArea from '../containers/infotechno/video-area'
import CaseStudyArea from '../containers/infotechno/case-study-area'
import TestimonialArea from '../containers/global/testimonial-area/section-two'
import BlogArea from '../containers/infotechno/blog-area'
import ContactArea from '../containers/infotechno/contact-area'
import GradiationArea from '../containers/index-services/gradiation-area'

const seo = {
    title: "Your Reliable Technology Partner",
    description: "Whether you need team augmentation, technical consulting, or full-service product development, we’re here to create whatever you can dream up.",
    image: "/dark-logo.png",
    pathname: "/",
  };

const Index = ({location}) => {
    return(
    <Layout location={location}>
    <SEO
      title={seo.title}
      description={seo.description}
      image={`${seo.image}`}
      pathname={seo.pathname}
    />
    <Header/>
    <main className="site-wrapper-reveal">
    <Hero/>
    {/*<FeaturesArea/>*/}
    <AboutServiceWrap>
        <AboutArea/>
        <ServicesArea/>
    </AboutServiceWrap>
    <FunFactArea/>
    <VideoArea/>
    <CaseStudyArea />
    <TestimonialArea/>
    <GradiationArea/>
    <BlogArea/>
     <ClientsArea/>
    <ContactArea/>
    </main>
    <Footer/>
    </Layout>
    )
}

export default Index; 