import styled from 'styled-components';

export const BlogMetaWrap = styled.div `
    margin-top: ${props => props.mt};
    margin-bottom: ${props => props.mb};
    margin-left: ${props => props.ml};
    margin-right: ${props => props.mr};
    i{
        margin-right: 10px;
        color: ${props => props.theme.colors.textColor};
    }
`;