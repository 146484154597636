import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import {Container, Row, Col} from '../../../components/ui/wrapper'
import SectionTitle from '../../../components/ui/section-title'
import Anchor from '../../../components/ui/anchor'
import ServiceBox from '../../../components/box-large-image/layout-one'
import {SectionWrap, SectionBottom} from './services-area.style'
import SectionMultiTitleWrap from '../../../components/ui/section-multi-title'

const ServicesArea = (props) => {
    const featuredDataQuery = useStaticQuery(graphql `query infotechnoServicesQueryData {
  infotechnoJson(id: {eq: "infotechno-featured-content"}) {
    title
    subtitle
    subtitle1
    subtitle2
  }
  allItServicesJson(
    sort: {order: DESC, fields: id}
    filter: {is_featured: {eq: true}, is_active: {eq: true}}
  ) {
    edges {
      node {
        fields {
          slug
        }
        id
        title
        excerpt
        banner_image {
          childImageSharp {
            gatsbyImageData(
              width: 370
              height: 370
              placeholder: TRACED_SVG
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  }
}
`); 
    const servicesSecData = featuredDataQuery.infotechnoJson;
    const servicesData = featuredDataQuery.allItServicesJson.edges;
    const {anchorStyle} = props
    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionMultiTitleWrap
                            title={servicesSecData.title}
                            subtitle={servicesSecData.subtitle}
                            subtitle1={servicesSecData.subtitle1}
                            subtitle2={servicesSecData.subtitle2}
                        />
                    </Col>
                </Row>
                <Row gutters={{lg: 70}}>
                    {servicesData.map(service => (
                        <Col lg={4} md={6} className="box-item" key={service.node.id}>
                            <ServiceBox
                                title={service.node.title}
                                desc={service.node.excerpt}
                                imageSrc={service.node.banner_image.childImageSharp}
                                path={`/services/${service.node.fields.slug}`}
                            />
                        </Col>
                    ))}
                </Row>
                <Row>
                    <Col lg={12}>
                        <SectionBottom>
                            <Anchor {...anchorStyle} path="/services">Learn more about our service offerings <i className="ml-1 far fa-long-arrow-right"></i></Anchor>
                        </SectionBottom>
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

ServicesArea.propTypes = {
    anchorStyle: PropTypes.object
}

ServicesArea.defaultProps = {
    anchorStyle: {
        color: 'primary',
        layout: 'underline',
        fontSize: '18px',
        fontWeight: 500,
        lineheight: 1.40,
        hover: {
            layout: 2
        }
    }
}

export default ServicesArea;