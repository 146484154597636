import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
    TestimonialWrap, 
    TestimonialSubject, 
    TestimonialReview, 
    AuthorInfoWrap, 
    AuthorMedia, 
    AuthorInfo, 
    AuthorName,
    AuthorOrgDetails,
    AuthorOrganisation,
    AuthorRole} from './testimonial.style'

const Testimonial = props => {
    const {authorImg, authorName, authroRole, subject, review, authorOrganisation, orgUrl, testimonialStyle} = props;
    const image = getImage(authorImg);
    const {wrapperStyle, infoStyle, imageStyle, reviewStyle, subjectStyle} = testimonialStyle;
    const authorImage = <GatsbyImage image={image} alt={authorName} />

    return (
        <TestimonialWrap {...wrapperStyle}>
            {subject && <TestimonialSubject {...subjectStyle}>{subject}</TestimonialSubject>}
            {review && <TestimonialReview {...reviewStyle}>{review}</TestimonialReview>}
            <AuthorInfoWrap {...infoStyle}>
                {authorImg && (
                    <AuthorMedia {...imageStyle}>
                        {authorImage}
                    </AuthorMedia>
                )}
                {(authorName || authroRole) && (
                    <AuthorInfo>
                        {authorName && <AuthorName>{authorName}</AuthorName>}
                        <AuthorOrgDetails>
                            {authroRole && <AuthorRole>{authroRole}</AuthorRole>}
                            { orgUrl && (<a href={orgUrl} target="_blank">
                                {authorOrganisation && <AuthorOrganisation>{authorOrganisation}</AuthorOrganisation>}
                            </a>)}
                            {authorOrganisation && !orgUrl && <AuthorOrganisation>{authorOrganisation}</AuthorOrganisation>}
                        </AuthorOrgDetails>
                    </AuthorInfo>
                )}
            </AuthorInfoWrap>
        </TestimonialWrap>
    )
}

Testimonial.propTypes = {
    authorImg: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    rating: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    authorName: PropTypes.string,
    authroRole: PropTypes.string,
    review: PropTypes.string,
}

Testimonial.defaultProps = {
    testimonialStyle: {
        wrapperStyle: {
            bgColor: '#fff',
            p: '51px 37px 40px',
            borderRadius: '5px'
        },
        subjectStyle: {
            mb: '15px'
        },
        reviewStyle: {
            fontSize: '18px',
            color: '#321451'
        },
        infoStyle: {
            mt: '20px'
        },
        imageStyle: {
            mr: '30px',
            circle: true
        }
    }
}

export default Testimonial;
