import React from 'react';
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import parse from 'html-react-parser'
import {Container, Row, Col} from '../../../components/ui/wrapper'
import Heading from '../../../components/ui/heading'
import Text from '../../../components/ui/text'
import Button from '../../../components/ui/button'
import {HeroWrapper, HeroTextBox, ImageBoxWrap, ImageBoxHero} from './hero-area.style'
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const HeroArea = (props) => {
    const heroDataQuery = useStaticQuery(graphql `query heroQueryData {
  infotechnoJson(id: {eq: "infotechno-hero-content"}) {
    title
    subtitle
    shortDesc
    path
    image1 {
        childImageSharp {
            gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
            )
        }
    }
    image2 {
      childImageSharp {
        gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
}
`);
    const {title, subtitle, shortDesc, path, image1, image2} = heroDataQuery.infotechnoJson;
    const {subtitleStyle, titleStyle, btnStyle} = props;
    const heroImage1 = getImage(image1);

    return (
        <HeroWrapper>
            <Container fluid ml="auto">
                <Row alignitems="center">
                    <Col md={6}>
                        <HeroTextBox>
                            {subtitle && <Heading {...subtitleStyle}>{subtitle}</Heading>}
                            {title && <Heading {...titleStyle}>{parse(title)}</Heading>}
                            {shortDesc && <Text>{shortDesc}</Text>}
                            {path && <Button {...btnStyle} to={path}>Book a Free Consultation</Button>}
                        </HeroTextBox>
                    </Col>
                    <Col md={6} style={{padding: "0px"}}>
                        <ImageBoxWrap>
                           {image1 && (
                                <ImageBoxHero>
                                     <GatsbyImage image={heroImage1} alt="Synergenie Solutions" loading = "eager" style={{height: '85vh'}}/>
                                </ImageBoxHero>
                            )}
                        </ImageBoxWrap>
                    </Col>
                </Row>
            </Container>
        </HeroWrapper>
    );
}

HeroArea.propTypes = {
    subtitleStyle: PropTypes.object,
    titleStyle: PropTypes.object,
    btnStyle: PropTypes.object
}

HeroArea.defaultProps = {
    subtitleStyle: {
        as: 'h6',
        color: 'primary',
        fontweight: 500,
        mb: '20px'
    },
    titleStyle: {
        as: 'h1',
        mb: '15px',
        fontweight: 500
    },
    btnStyle: {
        hover: '2',
        mt: '15px'
    }
}

export default HeroArea;