import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "../../../../components/ui/wrapper";
import Heading from "../../../../components/ui/heading";
import Counter from "../../../../components/counter/layout-three";
import { SectionWrap, HeaderWrap, FunFactHeaderCol } from "./section.style";
import SectionTitle from "../../../../components/ui/section-title";

const Section = ({ markHeading, headingStyle }) => {
  const funFactQueryData = useStaticQuery(graphql`
    query GloablFunFactThreeQuery {
      allFunFactJson {
        edges {
          node {
            count
            title
            heading
            allFunFact {
              id
              count
              fact_supertext
              fact_suffix
              title
            }
          }
        }
      }
    }
  `);
  const data = funFactQueryData.allFunFactJson.edges;
  return (
    <SectionWrap>
      <Container>
        {data.map((item, i) => (
          <Row key={i}>
            <FunFactHeaderCol lg={6} md={12}>
              <HeaderWrap>
                <Heading {...markHeading}>
                  <mark>{item.node.count}</mark> <span dangerouslySetInnerHTML={{ __html: item.node.title }}/>
                </Heading>
                <SectionTitle title={item.node.heading} {...headingStyle} />{" "}
              </HeaderWrap>
            </FunFactHeaderCol>
            <Col lg={6} md={12}>
              <Row>
                {item.node.allFunFact.map((factItem, i) => (
                  <Col sm={6} key={`fun-fact-${i}`}>
                    <Counter
                      text={factItem.title}
                      countTo={factItem.count}
                      factSuperText={factItem.fact_supertext}
                      factSuffix={factItem.fact_suffix}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        ))}
      </Container>
    </SectionWrap>
  );
};

Section.propTypes = {
  markHeading: PropTypes.object,
  headingStyle: PropTypes.object,
  countToStyle: PropTypes.object
};

Section.defaultProps = {
  markHeading: {
    layout: "highlight"
  },
  headingStyle: {
    align:'left',
    as: "h3",
    mt: "25px",
    fontSize: "38px",
    textAlign: "left",
    paddingTop: "20px",
    child: {
      color: "primary"
    },
    responsive: {
      large: {
        fontSize: "30px"
      },
      small: {
        fontSize: "24px"
      }
    }
  }
};

export default Section;
