import styled from 'styled-components';
import {device} from '../../../../theme';
import { Col } from "../../../../components/ui/wrapper";

export const SectionWrap = styled.div `
    padding-top: 120px;
    padding-bottom: 120px;
    @media ${device.medium}{
        padding-top: 80px;
        padding-bottom: 45px;
    }
    @media ${device.small}{
        padding-top: 60px;
        padding-bottom: 25px;
    }
`;

export const HeaderWrap = styled.div `
    margin-right: 3rem;
    @media ${device.medium}{
        margin-bottom: 20px;
    }
`;

export const FunFactHeaderCol = styled(Col) `
    @media ${device.large}{
        span: 4;
        offset: 1;
    }
    @media ${device.medium}{
        display: flex;
        justify-content: center;
        align-items: center;
    }
`