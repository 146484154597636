import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import List, { ListItem } from "../../../components/ui/list";
import Anchor from "../../../components/ui/anchor";

const RecentPostList = ({ listStyle }) => {
  const blogData = useStaticQuery(graphql`
    query RecentBlogQueryData {
      allMarkdownRemark(
        filter: { frontmatter: { isPublished: { eq: true } } }
        limit: 5
        sort: { fields: frontmatter___sort_order, order: DESC }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
            }
          }
        }
      }
    }
  `);
  const latestBlogs = blogData.allMarkdownRemark.edges;
  return (
    <List {...listStyle}>
      {latestBlogs &&
        latestBlogs.map((latestBlog, i) => (
          <ListItem key={`${latestBlog.node.fields.slug}-${i}`}>
            <Anchor path={`/blog/${latestBlog.node.fields.slug}`}>
              {latestBlog.node.frontmatter.title}
            </Anchor>
          </ListItem>
        ))}
    </List>
  );
};

RecentPostList.propTypes = {
  listStyle: PropTypes.object,
};

RecentPostList.defaultProps = {
  listStyle: {
    layout: "animated-arrow",
    child: {
      fontweight: 600,
      fontSize: "15px",
      color: "textColor",
      mb: "22px",
    },
  },
};

export default RecentPostList;
