import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
// import { MdPhone } from "react-icons/md";
import { Container, Row, Col, Box } from "../../../components/ui/wrapper";
import Heading from "../../../components/ui/heading";
import Text from "../../../components/ui/text";
import Anchor from "../../../components/ui/anchor";
import Button from "../../../components/ui/button";
import { SectionWrap, ContactInfoBox } from "./contact-area.style";
import Icon from "../../../components/ui/icon";
import parse from "html-react-parser";
import {Link} from 'gatsby';

const ContactArea = ({
  headingStyle,
  descStyle,
  conactInfoStyles: { iconStyle, titleStyle, phoneAnchorStyle, btnStyle }
}) => {
  const contactQueryData = useStaticQuery(graphql`
    query InfoTechContactQuery {
      sectionData: infotechnoJson(id: { eq: "infotechno-contact-content" }) {
        title
        desc
      }
      sectionBg: file(relativePath: { eq: "images/bg/contact-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 520, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      contactData: site {
        siteMetadata {
          contact {
            email
          }
        }
      }
    }
  `);
  const { title, desc } = contactQueryData.sectionData;
  const imageData = contactQueryData.sectionBg.childImageSharp.fluid;
  const { email } = contactQueryData.contactData.siteMetadata.contact;
  return (
    <SectionWrap fluid={imageData}>
      <Container>
        <Row alignitems="center">
          <Col lg={6}>
            <Box>
              {title && <Heading {...headingStyle}>{parse(title)}</Heading>}
              {desc && <Text {...descStyle}>{parse(desc)}</Text>}
            </Box>
          </Col>
          <Col lg={6}>
            <ContactInfoBox>
              <Icon {...iconStyle} iconName="fal fa-paper-plane" />
              <Heading {...titleStyle}>REACH OUT NOW!</Heading>
              {email && (
                <Heading>
                  <Anchor path={`mailto:${email}`} {...phoneAnchorStyle}>
                    {email}
                  </Anchor>
                </Heading>
              )}
              <Button 
                to="/free-consultation"
                as={Link}
                {...btnStyle}
              >
                Start A Conversation
              </Button>
            </ContactInfoBox>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

ContactArea.propTypes = {
  headingStyle: PropTypes.object
};

ContactArea.defaultProps = {
  headingStyle: {
    as: "h3",
    position: "relative",
    pl: "34px",
    fontweight: 600,
    lineHeight: 1.4,
    layout: "quote",
    child: {
      color: "primary"
    },
    before: {
      top: "50%",
      width: "4px",
      height: "94%",
      bgColor: "secondary",
      transform: "translateY(-50%)"
    }
  },
  descStyle: {
    mt: "15px",
    fontSize: "18px",
    ml: "34px"
  },
  conactInfoStyles: {
    iconStyle: {
      fontSize: "40px",
      mb: "14px",
      color: "primary"
    },
    titleStyle: {
      as: "h6",
      fontSize: "15px",
      letterspacing: "2px",
      texttransform: "uppercase",
      mb: "10px",
      mt: "10px"
    },
    phoneAnchorStyle: {
      color: "secondary",
      hover: {
        layout: 2,
        color: "secondary"
      }
    },
    btnStyle: {
      mt: "20px",
      skin: "secondary",
      minwidth: "230px",
      hover: {
        bgColor: "primary"
      }
    }
  }
};

export default ContactArea;
